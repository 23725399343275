//  Copyright © 2005-2015 David Caldwell <david@greenfelt.net> and Jim Radford <radford@greenfelt.net>

require("./node_modules/core-js/modules/es.symbol.js")
require("./node_modules/core-js/modules/es.symbol.iterator.js")
require("./node_modules/core-js/modules/es.array.iterator.js")
require("./node_modules/core-js/modules/web.dom-collections.iterator.js")

if (!Date.now) // Stupid IE.
    Date.now = function() { return (new Date).getTime() };

if (!window.performance || !window.performance.now) // Chrome Firefox Internet Explorer   Opera   Safari  Mobile Safari
    window.performance = { now: Date.now };         // 20.0   15.0    10.0                15.0    8.0     N/A

(function() {
    // https://www.gitorious.org/qtwebkit/kenneth-devel/commit/9e5f0f8fb25941fb89d8fc398cfc66acb44f9714
    //
    // I believe in WebCore/bindings/v8/V8AbstractEventListener.cpp there exists a case where Chrome can turn
    // on the getTime catcher and then accidentally leave it on (via the return statement in the middle). This
    // insidious because the catcher itself seems to be global (WebCore/bindings/v8/DateExtension.cpp lines 38
    // and 68). So if *anyone* throws then all the other pages are left counting their getTime()s. So here we
    // try to detect Chrome's getTime monkey patch with our own monkey patch and stomp over theirs with ours
    // when we do detect it.
    var getTime_orig = Date.prototype.getTime;
    var getTime_ours = Date.prototype.getTime = function() {
        if (Date.prototype.getTime != getTime_ours) {
            // Someone (chrome!) patched our getTime away. Lets patch it back.
            Date.prototype.getTime = getTime_ours;
            // report_error("getTime_ours", new Error("I caught someone monkey patching Date.getTime. I fixed it."));
        }
        return getTime_orig.apply(this, arguments);
    }
})();

// Taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/bind
if (!Function.prototype.bind) {
  Function.prototype.bind = function (oThis) {
    if (typeof this !== "function") {
      // closest thing possible to the ECMAScript 5 internal IsCallable function
      throw new TypeError("Function.prototype.bind - what is trying to be bound is not callable");
    }

    var aArgs = Array.prototype.slice.call(arguments, 1),
        fToBind = this,
        fNOP = function () {},
        fBound = function () {
          return fToBind.apply(this instanceof fNOP && oThis
                                 ? this
                                 : oThis,
                               aArgs.concat(Array.prototype.slice.call(arguments)));
        };

    fNOP.prototype = this.prototype;
    fBound.prototype = new fNOP();

    return fBound;
  };
}

if (!Array.from) // For stupid DOM things that look like arrays but don't act like them.
  Array.from = function(a) {
    var b = [];
    for (var i=0; i<a.length; i++)
        b[i] = a[i];
    return b;
  }

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
if (!Object.keys) {
  Object.keys = (function () {
    'use strict';
    var hasOwnProperty = Object.prototype.hasOwnProperty,
        hasDontEnumBug = !({toString: null}).propertyIsEnumerable('toString'),
        dontEnums = [
          'toString',
          'toLocaleString',
          'valueOf',
          'hasOwnProperty',
          'isPrototypeOf',
          'propertyIsEnumerable',
          'constructor'
        ],
        dontEnumsLength = dontEnums.length;

    return function (obj) {
      if (typeof obj !== 'object' && (typeof obj !== 'function' || obj === null)) {
        throw new TypeError('Object.keys called on non-object');
      }

      var result = [], prop, i;

      for (prop in obj) {
        if (hasOwnProperty.call(obj, prop)) {
          result.push(prop);
        }
      }

      if (hasDontEnumBug) {
        for (i = 0; i < dontEnumsLength; i++) {
          if (hasOwnProperty.call(obj, dontEnums[i])) {
            result.push(dontEnums[i]);
          }
        }
      }
      return result;
    };
  }());
}

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
if (!Object.assign)
    Object.assign = function(target, firstSource) {
      'use strict';
      if (target === undefined || target === null) {
        throw new TypeError('Cannot convert first argument to object');
      }

      var to = Object(target);
      for (var i = 1; i < arguments.length; i++) {
        var nextSource = arguments[i];
        if (nextSource === undefined || nextSource === null) {
          continue;
        }

        var keysArray = Object.keys(Object(nextSource));
        for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
          var nextKey = keysArray[nextIndex];
          var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
          if (desc !== undefined && desc.enumerable) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
      return to;
    };

if (!String.prototype.repeat)
    String.prototype.repeat = function(n) {
        for (var result = '', i=0; i<n; i++)
            result += String(this);
        return result;
    };

// IE11 doesn't support multiple arguments in classList.add() or classList.remove()
document.body.classList.add("x", "y");
if (!document.body.classList.contains("y")) {
    let native_add = window.DOMTokenList.prototype.add;
    window.DOMTokenList.prototype.add = function() {
        for (var i in arguments)
            native_add.call(this, arguments[i]);
    }
    let native_remove = window.DOMTokenList.prototype.remove;
    window.DOMTokenList.prototype.remove = function() {
        for (var i in arguments)
            native_remove.call(this, arguments[i]);
    }
}
document.body.classList.remove("x", "y");

// This isn't standard but it is quite useful :-)
window.DOMTokenList.prototype.set = function(present, property) {
    if (present) this.add(property);
    else this.remove(property); }; // Not sure where to put this and why it doesn't already exist.


function kill_the_intruders(o,name,delay) {
    var intruders=[];
    for (var i in o) {
        intruders.push({ [i]: String(o[i]) });
        delete o.constructor.prototype[i];
    }
    if (intruders.length)
        (delay ? require('./dom').on_load
               : function(f) { f() }).call(this, function() {
            var debug = require('./debug'), report_error=debug.report_error;
            report_error("kill_the_intruders",new Error("Found extra junk in "+name), {intruders: intruders});
        });
}
kill_the_intruders({}, "{}", true);
kill_the_intruders([], "[]", true);
setTimeout(function() {
    kill_the_intruders({}, "late {}", false);
    kill_the_intruders([], "late []", false);
}, 2000);
