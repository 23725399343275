// Copyright (C) 2005-2011 David Caldwell and Jim Radford, All Rights Reserved.

var ajax = require('./ajax'), postJSON=ajax.postJSON, makeurl=ajax.makeurl;
var prnt = require('./print'), printf=prnt.printf, print_structure=prnt.print_structure;
var lib = require('./lib'), merge=lib.merge;
var plat = require('./platform');
var doc = require('./dom'), URL=doc.URL;

window.debug = window.debug === true; // otherwise window.debug is <div id="debug">. Sigh.
var reported_errors = [];

if (URL.param.debug != undefined) window.debug = !URL.param.debug.match(/^(?:false|0)$/);

function report_error(where, error, extra) {
    if (window.debug) {
        printf("Caught error in %s!: %s\nerror=%j\nextra=%j\n", where, error, error, extra);
    } else {
        // The internal errors objects in Chrome and Firefox are special objects that won't let you enumerate their keys.
        var key = ["message", "name",                        // standard
                   "stack",                                  // Chrome, Firefox, Safari
                   "fileName", "lineNumber", "columnNumber", // Firefox
                   "sourceURL", "line",                      // Safari
                   "description", "number" ];                // IE
        var e = {};
        for (var k in key)
            if (key[k] in error)
                e[key[k]] = error[key[k]];
        postJSON(makeurl('error','report'), merge({Message: "Caught in "+where+": "+error,
                                                   e      : e,
                                                   browser:  plat.ua
                                                  }, extra ? { extra: extra } : {}),
                 function(data, error, status) {
                     if (data)
                         reported_errors.push(data.id);
                 });
    }
}


// Let the browser catch the error when we're not in production.
// Caveats: You can't use this exactly like try/catch:
//   try { return } catch {}; console.log("hello");
// is different from
//   maybe_try(function() { return }, function() {}); console.log("hello");
// Also, variables are not lifted through the functions, but they are lifted
// through the try.
function maybe_try(try_func, catch_func) {
    if (window.debug)
        return try_func()
    else {
        try { return try_func() }
        catch(e) { return catch_func(e); }
    }
}

// Kind of a combination of wrap() in lib.js and maybe_try, but return a
// function instead of calling the try_func directly. Useful for wrapping an
// entire callback.
function maybe_try_wrap(context, try_func, catch_func) {
    return function() {
        if (window.debug)
            return try_func.apply(context, arguments);
        else {
            try { return try_func.apply(context, arguments) }
            catch(e) { return catch_func.call(context, e) }
        }
    };
}

// Simplified try_wrap() for when you only want to report errors in the catch.
function try_report(try_func, where, extra) {
    return maybe_try(try_func, function(e) {
        report_error(where, e, extra);
    });
}


// Like try_report() but returns a function for use with callbacks.
// Analogous to maybe_try_wrap but with no context since there's
// only one function--just use .bind().
function try_reporter(try_func, where, extra) {
    return function() {
        try_report(try_func, where, extra);
    };
}

function bug(where, message, extra) {
    // We don't report 'extra' in debug mode, but it should be visible from the debugger.
    maybe_try(function() {
        throw(new Error(message));
    }, function(e) {
        report_error(where, e, extra);
    });
}

module.exports = {
    report_error: report_error,
    reported_errors: reported_errors,
    maybe_try: maybe_try,
    maybe_try_wrap: maybe_try_wrap,
    try_report: try_report,
    try_reporter: try_reporter,
    bug: bug
};
