// Copyright (C) 2005-2011 David Caldwell and Jim Radford, All Rights Reserved.

var plat = require('./platform');
require('./extend');

if (plat.node) // some basic fakery for when running in node.
    window.document = { cookie: "", URL: "", location: { search: "", pathname: "" } };

function on_load(f) {
    var old = window.onload;
    window.onload = function() {
        if (old) old();
        f();
    };
}
function on_unload(f) {
    var old = window.onunload;
    window.close = window.onunload = function() {
        if (old) old();
        f();
    };
}

function timeout(delay_ms, callback) {
    return setTimeout(callback, delay_ms);
}
function cancel_timeout(id) {
    clearTimeout(id);
}

var Deferral = Object.extend(
    function Deferral(callback) {
        this.callback = callback;
    }, {
        defer: function() {
            if (!this.timeout)
                this.timeout = timeout(0, this.on_timeout.bind(this));
        },
        on_timeout: function() {
            this.run(true)
        },
        run: function(timed_out) {
            if (this.timeout) {
                this.callback();
                if (!timed_out)
                    cancel_timeout(this.timeout);
                delete this.timeout;
            }
        }
    }
);

var requestAnimationFrame = window.requestAnimationFrame       || // future DOM?
                            window.mozRequestAnimationFrame    || // Firefox
                            window.msRequestAnimationFrame     || // IE 10 PP2+. Doesn't seem to work (IE9 either). Needs more investigation.
                            window.webkitRequestAnimationFrame;   // Chrome/Webkit
if (plat.ios) requestAnimationFrame = void 0; // Don't trust iOS. iOS7 seems to be super laggy.

function animate_next_frame(callback) {
    var me = animate_next_frame;
    if (!me.callback_count) {
        var handler = function(_timestamp /* Random units, see below */) {
            var timestamp = Date.now();
            me.callback_count = 0;
            me.calling_back = true;
            for (var c in me.callbacks)
                if (me.callbacks[c]) {
                    var callback = me.callbacks[c];
                    delete me.callbacks[c];
                    me.free_callbacks.push(c);
                    callback(timestamp - me.last_time, timestamp);
                }
            me.calling_back = false;
            me.last_time = timestamp;
            //if (me.callbacks && me.callbacks.length - callbacks.length > 10)
            //    printf("Increased anim frame callbacks by %d!!!\n", me.callbacks.length - callbacks.length);
            if (!me.callback_count) { // Don't let this dumb stuff get out of hand.
                delete me.callbacks;
                delete me.free_callbacks;
            }
        };
        me.callback_count = 0;
        if (!me.calling_back)
            // IE uses different timestamp units than Firefox (and the current W3C spec as of Feb 2013).
            // IE uses window.performance.now() is milliseconds since the page was opened. Chrome switched
            // from Date.now() to performance.now() and then back again. Now we just use Date.now() everywhere
            // and screw the stupid browsers.
            me.last_time = window.mozAnimationStartTime || Date.now();

        if (requestAnimationFrame)
            requestAnimationFrame(handler)
        else
            if (!me.calling_back)
                var interval_id = setInterval(function() {
                    handler();
                    if (!me.callback_count)
                        clearInterval(interval_id);
                }, 1000/30);

    }
    me.callback_count++;

    // The idea here is that the ID that we return can be passed to cancel_next_frame() right up until the
    // callback for that ID is called. This means that if there are 2 callbacks then the first callback can
    // call cancel_next_frame() with the ID of the second callback and it should be successfully canceled. So
    // we leave the callback list in place--but this means that we can't just push callbacks on the end of the
    // list all the time because it would grow out of control. For looping through the list looking for unused
    // elements seems slow too, so we keep track of the unused IDs with the free_callbacks list and only push
    // on the end of the list when there are no free callbacks in the list.

    if (!me.callbacks) me.callbacks = [];
    if (!me.free_callbacks) me.free_callbacks = [];
    if (me.free_callbacks.length) {
        var id = me.free_callbacks.shift();
        me.callbacks[id] = callback;
        return id;
    }
    me.callbacks.push(callback);
    return me.callbacks.length - 1;
}

function cancel_next_frame(callback_id) {
    var me = animate_next_frame;
    delete me.callbacks[callback_id];
}

function ParseURL(url) {
    var u = { raw:url };
    u.base = u.raw.split('?')[0];
    var m;
    if (m = u.base.match(/^((([^:]+):\/\/)?([-\w.]+)(:(\d+))?\/)?(.*)$/)) {
        u.protocol = m[3];
        u.address = m[4];
        u.port = m[6];
        u.path = (u.address ? "/" : "") + m[7];
    }
    u.params = u.raw.split('?')[1];
    u.param = {};
    if (u.params) {
        var param = u.params.split('&');
        for (var p in param) {
            if (m = param[p].match(/^([^=]+)=(.*)$/))
                u.param[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
        }
    }
    return u;
}
var URL = ParseURL(document.URL);

function CookieClass() {
    this.reload();
}
CookieClass.prototype.reload = function() {
    this.list = {};
    if (document.cookie == "")
        return;
    var cookie = document.cookie.split(/\s*;\s*/);
    for (var c in cookie)
        try {
            this.list[decodeURIComponent(cookie[c].split("=")[0])] = decodeURIComponent(cookie[c].split("=")[1]);
        } catch(e) {/*ignore bad cookies*/}
}
CookieClass.prototype.set = function(key, value, max_age, path, domain, port) {
    var cookie = key + "=";
    if (value  != undefined) cookie += encodeURIComponent(value);
    else /* Delete */        cookie += ";expires=Sat, 19 Jan 1974 04:31:24 GMT";
    if (path   != undefined) cookie += ";path="   + path;
    if (domain != undefined) cookie += ";domain=" + domain;
    if (port   != undefined) cookie += ";port="   + port;
    if (max_age!= undefined && value) {
        var m = max_age.match(/^(\d+)([smhdwy])$/);
        if (!m) require('./print').printf("Bad age \"%s\".", max_age);
        var seconds = { s:1, m:60, h:60*60, d:60*60*24, w:60*60*24*7, y:60*60*24*365 };
        cookie += ";max-age=" + m[1] * seconds[m[2]];
    }
    document.cookie = cookie;
    this.reload();
};
var Cookie = new CookieClass;

var doc = function (array, _document) {
    if (!_document) _document = window.document;
    var valid = function (a, array) {
        if (a === undefined || a === null)
            require('./print').printf("undefined value in: %j\n", array);
        else
            return true;
    };
    if (array.constructor === String) // allows for doc("Plain text")
        return _document.createTextNode(array);

    if (!array.length) return []; // [['div'],[]] => [['div']]
    if (array[0].constructor === Array) { // [[],[],...] ==> [],[],...
        var r = [];
        for (var i=0, l=array.length; i<l; i++) {
            n = doc(array[i], _document);
            if (n.constructor === Array)
                r=r.concat(n);
            else
                r.push(n);
        }
        return r;
    }

    var el = array[0];
    if (typeof el.nodeType === "undefined")
        el = el.charAt(0) == "#"
            ? _document.getElementById(el.substring(1))
            : _document.createElement(el);

    for (var i=1, l=array.length; i<l; i++) {
        var a = array[i];
        if (!valid(a, array))
            continue;
        else if (a.constructor === Array) {
            if (a.length !== 0) {
                var n = doc(a, _document);
                if (n.constructor == Array)
                    for (var $i=0, $l=n.length; $i<$l; $i++)
                        el.appendChild(n[$i]);
                else
                    el.appendChild(n);
            }
        }
        else if (a.constructor === Object)
            for (var p in a) {
                var ap = a[p];
                if (valid(ap,a) && ap.constructor === Object)
                  for (var s in ap)
                    el[p][s] = ap[s];
                else
                    if (_document === window.document)
                        el[p] = ap;
                    else
                        el.setAttribute(p, ap);
            }
        else if (typeof a.nodeType === "number")
            el.appendChild(a);
        else if (typeof a  === "function")
            a(el)
        else
            el.appendChild(_document.createTextNode(a));
    }
    return el;
}
doc.el = function(elem, props) {
        var el = document.createElement(elem);
        if (elem == "table") {
            arguments[0] = "tbody";
            var tbody = doc.el.apply(null, arguments);
            // Safari can't do: arguments = [ "xxx", {}, tbody];
            arguments[0] = "xxx";
            arguments[1] = {};
            arguments[2] = tbody;
            arguments.length=3
        }
        if (props)
            for (var p in props)
                if (p != "style")
                    el[p] = props[p];
                else
                    for (var s in props.style)
                        el.style[s] = props.style[s];

        if (arguments.length > 2)
            doc.append_array(el, arguments, 2);
        return el;
    },
doc.text = function(text) {
        return document.createTextNode(text);
    },
doc.id = function(id) { return document.getElementById(id); },
doc.append_array = function(dom_el, array, start) {
        for (var a=start||0; a<array.length; a++)
            if (array[a])
                if (array[a].constructor == Array) // Flatten nested arrays.
                    doc.append_array(dom_el, array[a]);
                else
                    dom_el.appendChild(array[a]);
    },
doc.appendTo = function(dom_el) {
        doc.append_array(dom_el, arguments, 1);
}
doc.clear = function(dom_el) {
    while (dom_el.firstChild)
        dom_el.removeChild(dom_el.firstChild);
    return dom_el;
}
doc.img = function(src) {
        var img;
        img=doc(["img", {src:src}]);
        img.set_src = function(src) { img.src = src; return img; };
        img.set_alt = function(alt) { img.alt = alt; return img; };
        img.set_size = function(size) { img.width = size.width; img.height = size.height; return img; };
        img.set_class = function(classname) { img.className = classname; return img; };
        return img;
    },
doc.opacity = function(el, percent) {
        el.style.opacity = percent;
        if (require('./platform').ie < 9) {
            el.style['-ms-filter'] = 'progid:DXImageTransform.Microsoft.Alpha(Opacity='+percent*100+')';
            el.style.filter = 'alpha(opacity=' + percent*100 + ')';
        }
    }
doc.click = function(el) {
    if (el.nodeName == 'A' && !el.onclick) // Firefox doesn't follow <a> links on synthetic click events :-(
        location = el.href;
    else if (document.createEvent) { // Firefox, IE11 DOM Level 2,3 Events:
        var evt = document.createEvent("MouseEvents");
        evt.initMouseEvent("click", true, true, document.defaultView,
                           1, 0, 0, 0, 0, false, false, false, false, 0, null);
        return el.dispatchEvent(evt);
    } else if (window.MouseEvent) {
        return el.dispatchEvent(new MouseEvent("click", {
            composed: true
        }));
    } else if (el.click) // old IE
        el.click();
};
doc.add_events_listener = function(el, events, callback, capture) {
    for (var e = 0; e < events.length; e++)
        el.addEventListener(events[e], callback, capture);
    return el;
};


function px(x) {
    return x+"px"
}

module.exports = {
    on_load:            on_load,
    on_unload:          on_unload,
    timeout:            timeout,
    cancel_timeout:     cancel_timeout,
    Deferral:           Deferral,
    animate_next_frame: animate_next_frame,
    cancel_next_frame:  cancel_next_frame,
    URL:                URL,
    ParseURL:           ParseURL,
    Cookie:             Cookie,
    doc:                doc,
    px:                 px
};
