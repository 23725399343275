// Copyright (C) 2005-2011 David Caldwell and Jim Radford, All Rights Reserved.
var plat = require('./platform');
var lib = require('./lib'), nbsp = lib.nbsp, map = lib.map;
var dom = require('./dom'), timeout=dom.timeout, on_load=dom.on_load;
var json = require('./json');

var print_array = [];
function print(s) {
    var flush;
    if (print_array.push(s) == 1) // cache up requests since innerHTML is slow
        timeout(0, flush = function() {
                    if (print_array.length) {
                        var d = document.getElementById("debug");
                        if (!d) return on_load(flush); // try to print later
                        d.style.display = "block"; // make it appear if it's hidden
                        var html = print_array.join("")
                            .replace(/</g,"&lt;").replace(/>/g,"&gt;")
                            .split("\n").join("<br>");
                        print_array = [];
                        if ("insertAdjacentHTML" in d)
                            d.insertAdjacentHTML("beforeend", html);
                        else
                            d.innerHTML += html
                    }
                });
}

if (plat.node) {
    print = function(s) {
        process.stdout.write(s);
    }
}

function pj(s, name) { print(json(s,name)); }
//on_load(function() { printf("This is a %j test\n", {a:1, b:"Hello", c:{d:"yo", e:-1}, d:[0,1,2,3,4,5], e:true, f:function(a,b) {return "hello"}}, "eat"); });

function print_structure(s, name) {
    if (!s)
        print(" "+name+" is undefined\n");
    else
        for (var i in s) {
            var rep;
            try { rep = s[i] == undefined        ? "undefined"     : 
                        typeof(s[i]) == "string" ? '"'+s[i]+'"'    :
                        s[i].toString            ? s[i].toString() : "???"; }
            catch(err) { rep = "!" + (typeof(err) == "string" ? err : typeof(err)) + "!"; }
            print(" "+name+"."+i+"="+rep+"\n");
        }
    print("--------------\n");
}

function sprintf(format) {
    var f = format.replace(/%%/g, "%q"); // hack so split doesn't lose our escaped %
    var chunk = f.split(/%/);
    var out="";
    if (f.slice(0,1) != '%' || chunk[0] == "") { // Skip the next steps on IE since it does split *WRONG*!!! Bastards.
        out = chunk[0];  // First one is a special case--no % was there so don't process it
        chunk.shift();
    }
    var a = 1;
    for (var c in chunk) {
        var m = chunk[c].match(/^([-#0 +]?)(\d*|\*)(?:\.(\d*|\*))?([dsfxqjc])/);
        if (m == undefined) { out += "[Bad printf sequence: %"+chunk[c]+"]"; continue; }
        if (m[3]=="") m[3]=0; // 3. => 3.0
        var width = parseInt(m[2] == "*" ? arguments[a++] : m[2]),
            prec  = parseInt(m[3] == "*" ? arguments[a++] : m[3]),
            flags = {};
        for (var i=0;i<m[1].length;i++)
            flags[m[1].slice(i,1)] = 1;
        if (m) {
            chunk[c] = chunk[c].slice(m[0].length); // remove the params from the start
            var arg="", prefix=" ", suffix=" ";
            function pad(s, c, n, right) {
                var p = "";
                if (n) for (var i=0; i<Math.max(0, n - s.length); i++) p += c;
                return right ? s+p : p+s;
            }
            switch(m[4]) {
                case 'q': out += "%"; break;
                case 'd': arg += pad(parseInt(arguments[a++]),"0",prec); break;
                case 's': arg += arguments[a++]; if (prec) arg = arg.slice(0, prec); break;
                case 'f': arg += parseFloat(arguments[a++]).toFixed(isNaN(prec) ? 10 : prec); break;
                case 'x': arg += pad(parseInt(arguments[a++]).toString(16), "0", prec); break;
                case 'j': arg += json(arguments[a++]); break;
                case 'c': arg += typeof arguments[a] == "string" ? arguments[a++].slice(0,1)
                                                                 : String.fromCharCode(arguments[a++]); break;
            }
            if (flags['-'] || prec) flags['0'] = 0; // read the man page
            out += pad(arg, flags['0'] ? "0" : nbsp, width, flags['-']);
        }
        out += chunk[c];
    }
    return out;
}

function printf(format) {
    print(sprintf.apply(null,arguments));
} 

function say(hash) {
    var s = [];
    for (var v in hash)
        s.push(v + "=" + hash[v]);
    print(s.join(", ")+"\n");
} 

module.exports = {
    print: print,
    pj: pj,
    print_structure: print_structure,
    sprintf: sprintf,
    printf: printf,
    say: say
};
